/*
import { configureStore } from '@reduxjs/toolkit'
import userReducer from "./user"

export const store = configureStore({
  reducer: {
    user: userReducer,
  },
})
*/

import {configureStore} from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage'
import {combineReducers} from "redux"; 
import { persistReducer, persistStore} from 'redux-persist'
import thunk from 'redux-thunk'
import userReducer from "./user"

const reducers = combineReducers({
  user: userReducer,
});

const persistConfig = {
    key: 'root',
    storage
};

const persistedReducer = persistReducer(persistConfig, reducers);


const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk]
});

let persistor = persistStore(store)

export {store, persistor}